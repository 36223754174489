import React from 'react';
import PropTypes from 'prop-types';

import { LsiContext } from '../../../../../common/contexts';
import { Button, ButtonBar, Modal } from '../../../../../common/components';
import { ListSelector } from '../../../../../common/components/forms';

import { COLUMN_IDS, columnTypes, getColumnDefinitionMap } from './ModelGroupListModalForm.columnDefs';

const propTypes = {
  modelGroupsInProductionCorridor: PropTypes.array,
  modelGroupsSpecificConfig: PropTypes.array,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  shown: PropTypes.bool
};

const defaultProps = {
  modelGroupsInProductionCorridor: [],
  modelGroupsSpecificConfig: [],
  onClose: null,
  onSave: null,
  shown: false
};
const ModelGroupListModalFormComponent = (props) => {

  const lsi = React.useContext(LsiContext);
  const { modelGroupsInProductionCorridor, modelGroupsSpecificConfig } = props;
  const modelGroupColumnDef = getColumnDefinitionMap[COLUMN_IDS.MODEL_GROUP](lsi);
  const [modelGroupListSelected, setModelGroupListSelected] = React.useState(modelGroupsSpecificConfig);

  const handleSave = () => {
    const { onSave } = props;

    const deletedModelGroups = modelGroupsSpecificConfig.filter(mg => !modelGroupListSelected.includes(mg));
    const addedModelGroups = modelGroupListSelected.filter(mg => !modelGroupsSpecificConfig.includes(mg));

    onSave && onSave({
      values: modelGroupListSelected,
      addedModelGroups: addedModelGroups,
      deletedModelGroups: deletedModelGroups
    });
  };

  return (
    <Modal
      header={lsi.getLSIItem('PVT.PAGE_TITLE.EDIT_MODEL_GROUPS')}
      onClose={props.onClose}
      shown={props.shown}
      size={'m'}
    >
      <ListSelector
        columnDefAvailableOptions={[
          modelGroupColumnDef
        ]}
        columnDefSelectedOptions={[
          modelGroupColumnDef
        ]}
        columnTypes={columnTypes}
        enableRangeSelection
        items={modelGroupsInProductionCorridor}
        onChange={(params) => {
          setModelGroupListSelected(params.map(item => item.modelGroup));
        }}
        value={modelGroupListSelected}
      />
      <ButtonBar
        align={ButtonBar.align.center}
        formControlsBar
      >
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.CANCEL')}
          onClick={props.onClose}
          type={Button.type.SECONDARY}
        />
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.SAVE')}
          onClick={handleSave}
          type={Button.type.PRIMARY}
        />
      </ButtonBar>
    </Modal>
  );
};

ModelGroupListModalFormComponent.defaultProps = defaultProps;
ModelGroupListModalFormComponent.propTypes = propTypes;

export default ModelGroupListModalFormComponent;