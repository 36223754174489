import { simpleNullishValueFormatter } from '../../../../../../../common/utils/formatters';
import AbstractRowUIDefinition from '../AbstractRowUIDefinition';
import RowUIDefinitionCodes from '../RowUIDefinitionCodes';

class DealerRUID_CZ extends AbstractRowUIDefinition {

  /** @override */
  getCode = () => {
    return RowUIDefinitionCodes.DEALER__CZ__ROW;
  }
  /** @override */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_HEADER_ROW_TYPE.DEALER_NUMBER');
  }
  /** @override */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    const { commission, agLsi } = context;
    return commission.legalSuccession ? agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DEALER_LEGAL_SUCCESSION') : null;
  }
  /** @override */
  requiredStateValueGetter = ({ data, context }) => {
    const { commission } = context;
    let dealerNumber = commission.commissionPreviousData.dealerNumber;
    if (commission.legalSuccession) {
      dealerNumber += ` / ${commission.legalSuccession.successor}`;
    }
    return dealerNumber;
  }
  /** @override */
  requiredStateValueFormatter = ({ data, context, value }) => {
    return simpleNullishValueFormatter(value, '-');
  }
  /** @override */
  currentStateValueGetter = ({ data, context }) => {
    const { commission } = context;
    const { commissionCurrentData } = commission;

    return commissionCurrentData.dealerNumber;
  }
  /** @override */
  currentStateValueFormatter = ({ data, context, value }) => {
    return simpleNullishValueFormatter(value, '-');
  }
}

const dealerRUID_CZ = new DealerRUID_CZ();

export default dealerRUID_CZ;