import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const dealersApi = ApiServiceInstances.partner.dealersApi;

export const GET_ACTIVE_DEALERS_REQUEST = 'GET_ACTIVE_DEALERS_REQUEST';
export const GET_ACTIVE_DEALERS_SUCCESS = 'GET_ACTIVE_DEALERS_SUCCESS';
export const GET_ACTIVE_DEALERS_FAILURE = 'GET_ACTIVE_DEALERS_FAILURE';

const initialState = {
  activeDealersData: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_DEALERS_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_ACTIVE_DEALERS_SUCCESS: {
      return { ...state, isFetching: false, activeDealersData: action.payload };
    }
    case GET_ACTIVE_DEALERS_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false, activeDealersData: null };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetActiveDealersList = (opts) => {
  return {
    [CALL_API]: {
      apiFunction: () => dealersApi.getActiveDealerBasicInfosWithHttpInfo(opts),
      types: [GET_ACTIVE_DEALERS_REQUEST, GET_ACTIVE_DEALERS_SUCCESS, GET_ACTIVE_DEALERS_FAILURE],
      middlewareConfig: {
        validate: function () {
          // not showing standard error message
          return false;
        }
      }
    }
  };
};