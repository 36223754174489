import React, { Fragment } from 'react';
import { Bricks } from 'uu5g04';
import PropTypes from 'prop-types';
import 'uu5g04/forms';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';

import { Button, ButtonBar } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import './DealerConfigProductionCorridorButtonBar.scss';
import { Checkbox } from '../../../../../common/components/forms';

const propTypes = {
  disabledAllTables: PropTypes.bool,
  editableProductionCorridor: PropTypes.bool,
  editableSpecification: PropTypes.bool,
  onCancel: PropTypes.func,
  onReloadProductionCorridor: PropTypes.func,
  onUpdateProductionCorridor: PropTypes.func,
  pageDescription: PropTypes.shape({
    generalSettingDescription: PropTypes.string,
    legend: PropTypes.string,
    legendDescriptionOff: PropTypes.string,
    legendDescriptionOn: PropTypes.string,
    specificSettingDescription: PropTypes.string,
    tableSpecification: PropTypes.string
  }),
  setEditable: PropTypes.func
};

const defaultProps = {
  disabledAllTables: false,
  editableProductionCorridor: false,
  editableSpecification: false,
  onCancel: undefined,
  onReloadProductionCorridor: undefined,
  onUpdateProductionCorridor: undefined,
  pageDescription: PropTypes.shape({
    generalSettingDescription: null,
    legend: null,
    legendDescriptionOff: null,
    legendDescriptionOn: null,
    specificSettingDescription: null,
    tableSpecification: null
  }),
  setEditable: undefined
};

const DealerConfigProductionCorridorButtonBar = (props) => {
  const lsi = React.useContext(LsiContext);
  const { editableProductionCorridor, editableSpecification, setEditable, pageDescription, disabledAllTables } = props;
  const { onCancel, onReloadProductionCorridor, onUpdateProductionCorridor } = props;

  const effectivenessLabel = () => {
    return (<div className={'effectiveness-label'}>
      <Icon color={'green'} path={mdiInformationOutline} size={0.7}></Icon>
      <Bricks.Text
        className={'effectiveness-label-text'}>{lsi.getLSIItem('PVT.LABEL.PRODUCTION_CORRIDOR_EFFECTIVENESS')}</Bricks.Text>
    </div>);
  };

  let buttons;
  if (editableProductionCorridor) {
    buttons = <Fragment>
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.CANCEL')}
        onClick={onCancel}
        type={Button.type.SECONDARY}
      />
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.SAVE')}
        onClick={onUpdateProductionCorridor}
        type={Button.type.PRIMARY}
      />
      {effectivenessLabel()}
    </Fragment>;
  } else {
    buttons = <Fragment>
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.EDIT')}
        disabled={editableSpecification}
        onClick={setEditable}
        type={Button.type.PRIMARY}
      />
      <Button
        content={lsi.getLSIItem('COMMON.BUTTON.RELOAD')}
        disabled={editableSpecification}
        onClick={() => {
          onReloadProductionCorridor();
        }}
        type={Button.type.SECONDARY}
      />
      {effectivenessLabel()}
    </Fragment>;
  }

  const LegendItemCheckBox = ({ checked, text }) => (
    <div className="legend-item">
      <Checkbox disabled={disabledAllTables} disableChange type={1} value={checked}/>
      <Bricks.Text className={'legend-item-text'}>{text}</Bricks.Text>
    </div>
  );

  return (
    <div className="ovex-dealer-config-button-bar">
      <div>
        <Bricks.Text className="general-setting">{pageDescription.generalSettingDescription}</Bricks.Text>
        <ButtonBar>{buttons}</ButtonBar>
      </div>
      <div className="legend">
        <div className="vertical-line"/>
        <div>
          <LegendItemCheckBox checked={true} text={pageDescription.legendDescriptionOn}/>
          <LegendItemCheckBox checked={false} text={pageDescription.legendDescriptionOff}/>
        </div>
      </div>
    </div>
  );
};

DealerConfigProductionCorridorButtonBar.propTypes = propTypes;
DealerConfigProductionCorridorButtonBar.defaultProps = defaultProps;

export default DealerConfigProductionCorridorButtonBar;