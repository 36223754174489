import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';
import { DealerWithSpecificConfigurationResponseDTO, ProductionCorridorDTO } from '@ovex/pvt-web-api';
import { ActiveDealerBasicInfoDTO } from '@ovex/partner-web-api';

import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import DealerConfig from '../DealerConfig/DealerConfig';

class DealerConfigStockCar extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    activeDealersData: PropTypes.arrayOf(PropTypes.instanceOf(ActiveDealerBasicInfoDTO)),
    dealerConfigData: PropTypes.instanceOf(DealerWithSpecificConfigurationResponseDTO),
    isFetchingDealerConfig: PropTypes.bool,
    isFetchingProductionCorridor: PropTypes.bool,
    onGetActiveDealersList: PropTypes.func.isRequired,
    onGetDealerConfigStockCarList: PropTypes.func.isRequired,
    onGetProductionCorridorList: PropTypes.func.isRequired,
    onUpdateConfigSpecification: PropTypes.func.isRequired,
    onUpdateProductionCorridorBulk: PropTypes.func.isRequired,
    productionCorridorData: PropTypes.arrayOf(PropTypes.instanceOf(ProductionCorridorDTO))

  };

  static defaultProps = {
    activeDealersData: null,
    dealerConfigData: null,
    isFetchingDealerConfig: true,
    isFetchingProductionCorridor: true,
    productionCorridorData: null
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this.handleReloadProductionCorridor();
    this.handleReloadDealerConfig();
  }

  storeAlertRef = (ref) => {
    this.alert = ref;
  };

  handleReloadActiveDealers = async () => {
    const { onGetActiveDealersList } = this.props;

    try {
      await onGetActiveDealersList();
    } catch (e) {
      const lsi = this.context;
      this.alert.addAlert({
        colorSchema: 'warning',
        content: lsi.getLSIItem('PVT.ERROR_MESSAGE.GET_ACTIVE_DEALER_FAILURE')
      });
    }
  };

  handleReloadDealerConfig = async () => {
    const { onGetDealerConfigStockCarList } = this.props;

    try {
      await onGetDealerConfigStockCarList();
    } catch (e) {
      const lsi = this.context;
      this.alert.addAlert({
        colorSchema: 'danger',
        content: lsi.getLSIItem('PVT.ERROR_MESSAGE.GET_DEALER_CONFIG_FAILURE')
      });
    }
  };
  handleReloadProductionCorridor = async () => {
    const { onGetProductionCorridorList } = this.props;

    try {
      await onGetProductionCorridorList();
    } catch (e) {
      const lsi = this.context;
      this.alert.addAlert({
        colorSchema: 'danger',
        content: lsi.getLSIItem('PVT.ERROR_MESSAGE.GET_PRODUCTION_CORRIDOR_FAILURE')
      });
    }
  };
  handleUpdateConfigSpecification = async (changes) => {
    const lsi = this.context;
    const { onUpdateConfigSpecification } = this.props;

    try {
      const response = await onUpdateConfigSpecification(changes);
      this.alert.addAlert({ content: lsi.getLSIItem('PVT.CONFIRM_MESSAGE.UPDATE_DEALER_CONFIG_SUCCESS') });
      this.handleReloadDealerConfig();
      return response;
    } catch (e) {
      return e;
    }
  };
  handleUpdateProductionCorridorBulk = async (changes) => {
    const lsi = this.context;
    const { onUpdateProductionCorridorBulk } = this.props;

    try {
      const response = await onUpdateProductionCorridorBulk(changes);
      this.alert.addAlert({ content: lsi.getLSIItem('PVT.ERROR_MESSAGE.UPDATE_PRODUCTION_CORRIDOR_GLOBAL_SPECIFICATION_SUCCESS') });
      this.handleReloadProductionCorridor();
      return response;
    } catch (e) {
      return e;
    }
  };

  render() {
    const lsi = this.context;
    const {
      activeDealersData,
      isFetchingDealerConfig,
      isFetchingProductionCorridor,
      productionCorridorData,
      dealerConfigData
    } = this.props;
    const isFetching = isFetchingDealerConfig || isFetchingProductionCorridor;

    return (
      <Page
        header={lsi.getLSIItem('PVT.PAGE_TITLE.DEALER_STOCK_CAR')}
        loading={isFetching}
      >
        <DealerConfig
          activeDealersData={activeDealersData}
          attributeName={'stockCar'}
          dealerConfigData={dealerConfigData}
          isFetchingDealerConfig={isFetchingDealerConfig}
          isFetchingProductionCorridor={isFetchingProductionCorridor}
          onReloadActiveDealers={this.handleReloadActiveDealers}
          onReloadConfigSpecification={this.handleReloadDealerConfig}
          onReloadProductionCorridor={this.handleReloadProductionCorridor}
          onUpdateConfigSpecification={this.handleUpdateConfigSpecification}
          onUpdateProductionCorridor={this.handleUpdateProductionCorridorBulk}
          pageDescription={{
            tableSpecification: lsi.getLSIItem('PVT.TABLE_HEADER.STOCK_CAR'),
            generalSettingDescription: lsi.getLSIItem('PVT.LABEL.GENERAL_SETTINGS.STOCK_CAR'),
            legend: lsi.getLSIItem('PVT.LABEL.LEGEND'),
            legendDescriptionOn: lsi.getLSIItem('PVT.LABEL.LEGEND_DESCRIPTION_ON.STOCK_CAR'),
            legendDescriptionOff: lsi.getLSIItem('PVT.LABEL.LEGEND_DESCRIPTION_OFF.STOCK_CAR'),
            specificSettingDescription: lsi.getLSIItem('PVT.LABEL.SPECIFICATION_SETTINGS.STOCK_CAR')
          }}
          productionCorridorData={productionCorridorData}
        />
        <Bricks.AlertBus
          hidden
          ref_={this.storeAlertRef}
        />
      </Page>
    );
  }
}

export default DealerConfigStockCar;
