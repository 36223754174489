import React, { Fragment } from 'react';
import { Bricks } from 'uu5g04';

import { Button, ButtonBar } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import './DealerConfigSpecificationButtonBar.scss';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';

const propTypes = {
  disabledModelGroup: PropTypes.bool,
  editableProductionCorridor: PropTypes.bool,
  editableSpecification: PropTypes.bool,
  onCancel: PropTypes.func,
  onReloadConfigSpecification: PropTypes.func,
  onSave: PropTypes.func,
  onSetDealerModalShown: PropTypes.func,
  onSetEditableSpecification: PropTypes.func,
  onSetModelGroupModalShown: PropTypes.func,
  pageDescription: PropTypes.shape({
    generalSettingDescription: PropTypes.string,
    legend: PropTypes.string,
    legendDescriptionOff: PropTypes.string,
    legendDescriptionOn: PropTypes.string,
    specificSettingDescription: PropTypes.string,
    tableSpecification: PropTypes.string
  })
};

const defaultProps = {
  disabledModelGroup: false,
  editableProductionCorridor: false,
  editableSpecification: false,
  onCancel: null,
  onReloadConfigSpecification: null,
  onSave: null,
  onSetDealerModalShown: null,
  onSetEditableSpecification: null,
  onSetModelGroupModalShown: null,
  pageDescription: PropTypes.shape({
    generalSettingDescription: null,
    legend: null,
    legendDescriptionOff: null,
    legendDescriptionOn: null,
    specificSettingDescription: null,
    tableSpecification: null
  })
};

const DealerConfigSpecificationButtonBar = (props) => {
  const lsi = React.useContext(LsiContext);
  const { editableSpecification, editableProductionCorridor, pageDescription, disabledModelGroup } = props;
  const {
    onSetEditableSpecification,
    onSetDealerModalShown,
    onSetModelGroupModalShown,
    onSave,
    onReloadConfigSpecification,
    onCancel
  } = props;

  const effectivenessLabel = () => {
    return (<div className={'effectiveness-label'}>
      <Icon color={'green'} path={mdiInformationOutline} size={0.7}></Icon>
      <Bricks.Text
        className={'effectiveness-label-text'}>{lsi.getLSIItem('PVT.LABEL.SPECIFICATION_EFFECTIVENESS_LABEL')}</Bricks.Text>
    </div>);
  };

  let buttons;
  if (editableSpecification) {
    buttons = <Fragment>
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.CANCEL')}
        onClick={() => {
          onSetEditableSpecification(false);
          onCancel();
        }}
        type={Button.type.SECONDARY}
      />
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.SAVE')}
        onClick={() => {
          onSave();
        }}
        type={Button.type.PRIMARY}
      />
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.EDIT_DEALERS')}
        onClick={() => {
          onSetDealerModalShown();
        }}
        type={Button.type.PRIMARY}
      />
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.EDIT_MODEL_GROUPS')}
        disabled={disabledModelGroup}
        onClick={() => {
          onSetModelGroupModalShown();
        }}
        type={Button.type.PRIMARY}
      />
      {effectivenessLabel()}
    </Fragment>;
  } else {
    buttons = <Fragment>
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.EDIT')}
        disabled={editableProductionCorridor}
        onClick={() => {
          onSetEditableSpecification(true);
        }}
        type={Button.type.PRIMARY}
      />
      <Button
        content={lsi.getLSIItem('COMMON.BUTTON.RELOAD')}
        disabled={editableProductionCorridor}
        onClick={() => {
          onReloadConfigSpecification();
        }}
        type={Button.type.SECONDARY}
      />
      {effectivenessLabel()}
    </Fragment>;
  }
  return (
    <div className={'ovex-dealer-config-specification-button-bar'}>
      <Bricks.Text className="general-setting">{pageDescription.specificSettingDescription}</Bricks.Text>
      <ButtonBar>{buttons}</ButtonBar>
    </div>
  );

};
DealerConfigSpecificationButtonBar.defaultProps = defaultProps;
DealerConfigSpecificationButtonBar.propTypes = propTypes;
export default DealerConfigSpecificationButtonBar;
