export const COLUMN_IDS = {
  DEALER_NAME: 'dealerName',
  DEALER_NUMBER: 'dealerNumber',
};

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab']
  }
};


export const getColumnDefinitionMap = {
  [COLUMN_IDS.DEALER_NAME]: (lsi) => ({
    colId: COLUMN_IDS.DEALER_NAME,
    valueGetter: 'data.dealerName',
    headerName: lsi.getLSIItem('PVT.TABLE_HEADER.DEALER'),
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    flex: 1
  }),
  [COLUMN_IDS.DEALER_NUMBER]: (lsi) => ({
    colId: COLUMN_IDS.DEALER_NUMBER,
    valueGetter: 'data.dealerNumber',
    headerName: lsi.getLSIItem('PVT.TABLE_HEADER.NUMBER'),
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    width: 90
  })
};

