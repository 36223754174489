"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AccountingBasisDTO", {
  enumerable: true,
  get: function get() {
    return _AccountingBasisDTO["default"];
  }
});
Object.defineProperty(exports, "ApiClient", {
  enumerable: true,
  get: function get() {
    return _ApiClient["default"];
  }
});
Object.defineProperty(exports, "COrderDTOVLD", {
  enumerable: true,
  get: function get() {
    return _COrderDTOVLD["default"];
  }
});
Object.defineProperty(exports, "COrderHeaderAccountPartDTOPRG", {
  enumerable: true,
  get: function get() {
    return _COrderHeaderAccountPartDTOPRG["default"];
  }
});
Object.defineProperty(exports, "COrderHeaderAccountPartDTOVLD", {
  enumerable: true,
  get: function get() {
    return _COrderHeaderAccountPartDTOVLD["default"];
  }
});
Object.defineProperty(exports, "COrderHeaderAccountWithPGItemsDTOPRG", {
  enumerable: true,
  get: function get() {
    return _COrderHeaderAccountWithPGItemsDTOPRG["default"];
  }
});
Object.defineProperty(exports, "COrderHeaderAccountWithPGItemsDTOVLD", {
  enumerable: true,
  get: function get() {
    return _COrderHeaderAccountWithPGItemsDTOVLD["default"];
  }
});
Object.defineProperty(exports, "COrderModeEnum", {
  enumerable: true,
  get: function get() {
    return _COrderModeEnum["default"];
  }
});
Object.defineProperty(exports, "CarSpecificationDTOVLD", {
  enumerable: true,
  get: function get() {
    return _CarSpecificationDTOVLD["default"];
  }
});
Object.defineProperty(exports, "ClaimOriginEnum", {
  enumerable: true,
  get: function get() {
    return _ClaimOriginEnum["default"];
  }
});
Object.defineProperty(exports, "ClaimTypeHeaderEnum", {
  enumerable: true,
  get: function get() {
    return _ClaimTypeHeaderEnum["default"];
  }
});
Object.defineProperty(exports, "ClaimTypeHeaderFilterEnum", {
  enumerable: true,
  get: function get() {
    return _ClaimTypeHeaderFilterEnum["default"];
  }
});
Object.defineProperty(exports, "ClaimTypeItemEnum", {
  enumerable: true,
  get: function get() {
    return _ClaimTypeItemEnum["default"];
  }
});
Object.defineProperty(exports, "CohPartStatusEnum", {
  enumerable: true,
  get: function get() {
    return _CohPartStatusEnum["default"];
  }
});
Object.defineProperty(exports, "CommissionOrderTypeEnum", {
  enumerable: true,
  get: function get() {
    return _CommissionOrderTypeEnum["default"];
  }
});
Object.defineProperty(exports, "CommissionPGClaimResultDTOPGR", {
  enumerable: true,
  get: function get() {
    return _CommissionPGClaimResultDTOPGR["default"];
  }
});
Object.defineProperty(exports, "CommissionSnapshotDTOPGR", {
  enumerable: true,
  get: function get() {
    return _CommissionSnapshotDTOPGR["default"];
  }
});
Object.defineProperty(exports, "CommissionSnapshotDTOVLD", {
  enumerable: true,
  get: function get() {
    return _CommissionSnapshotDTOVLD["default"];
  }
});
Object.defineProperty(exports, "CommissionSpecificationDTOVLD", {
  enumerable: true,
  get: function get() {
    return _CommissionSpecificationDTOVLD["default"];
  }
});
Object.defineProperty(exports, "CommissionStatusIntervalEnum", {
  enumerable: true,
  get: function get() {
    return _CommissionStatusIntervalEnum["default"];
  }
});
Object.defineProperty(exports, "CommissionValidationResultDTOVLD", {
  enumerable: true,
  get: function get() {
    return _CommissionValidationResultDTOVLD["default"];
  }
});
Object.defineProperty(exports, "CommissionValidatorApi", {
  enumerable: true,
  get: function get() {
    return _CommissionValidatorApi["default"];
  }
});
Object.defineProperty(exports, "ConditionCiColumnDefinitionDTOVLD", {
  enumerable: true,
  get: function get() {
    return _ConditionCiColumnDefinitionDTOVLD["default"];
  }
});
Object.defineProperty(exports, "ConditionHeaderRowDTOVLD", {
  enumerable: true,
  get: function get() {
    return _ConditionHeaderRowDTOVLD["default"];
  }
});
Object.defineProperty(exports, "ConditionItemColumnDTOVLD", {
  enumerable: true,
  get: function get() {
    return _ConditionItemColumnDTOVLD["default"];
  }
});
Object.defineProperty(exports, "ConditionResultHeaderEnum", {
  enumerable: true,
  get: function get() {
    return _ConditionResultHeaderEnum["default"];
  }
});
Object.defineProperty(exports, "ConditionResultItemEnum", {
  enumerable: true,
  get: function get() {
    return _ConditionResultItemEnum["default"];
  }
});
Object.defineProperty(exports, "CurrencyEnum", {
  enumerable: true,
  get: function get() {
    return _CurrencyEnum["default"];
  }
});
Object.defineProperty(exports, "ErrorDTO", {
  enumerable: true,
  get: function get() {
    return _ErrorDTO["default"];
  }
});
Object.defineProperty(exports, "ErrorItemDTO", {
  enumerable: true,
  get: function get() {
    return _ErrorItemDTO["default"];
  }
});
Object.defineProperty(exports, "ErrorTypeEnum", {
  enumerable: true,
  get: function get() {
    return _ErrorTypeEnum["default"];
  }
});
Object.defineProperty(exports, "LegalSuccessorTO", {
  enumerable: true,
  get: function get() {
    return _LegalSuccessorTO["default"];
  }
});
Object.defineProperty(exports, "ModelGroupDTO", {
  enumerable: true,
  get: function get() {
    return _ModelGroupDTO["default"];
  }
});
Object.defineProperty(exports, "PGAccountingDTO", {
  enumerable: true,
  get: function get() {
    return _PGAccountingDTO["default"];
  }
});
Object.defineProperty(exports, "PGAccountingNewDTO", {
  enumerable: true,
  get: function get() {
    return _PGAccountingNewDTO["default"];
  }
});
Object.defineProperty(exports, "PGIBulkUpdateDTOITM", {
  enumerable: true,
  get: function get() {
    return _PGIBulkUpdateDTOITM["default"];
  }
});
Object.defineProperty(exports, "PGISpecificationDTO", {
  enumerable: true,
  get: function get() {
    return _PGISpecificationDTO["default"];
  }
});
Object.defineProperty(exports, "PGISpecificationSnapshotEnum", {
  enumerable: true,
  get: function get() {
    return _PGISpecificationSnapshotEnum["default"];
  }
});
Object.defineProperty(exports, "PGISpecificationTypeEnum", {
  enumerable: true,
  get: function get() {
    return _PGISpecificationTypeEnum["default"];
  }
});
Object.defineProperty(exports, "PGItemDTOPGR", {
  enumerable: true,
  get: function get() {
    return _PGItemDTOPGR["default"];
  }
});
Object.defineProperty(exports, "PGItemValidationResultDTOPRG", {
  enumerable: true,
  get: function get() {
    return _PGItemValidationResultDTOPRG["default"];
  }
});
Object.defineProperty(exports, "PGItemValidationResultDTOVLD", {
  enumerable: true,
  get: function get() {
    return _PGItemValidationResultDTOVLD["default"];
  }
});
Object.defineProperty(exports, "PGUniverseInfoDTO", {
  enumerable: true,
  get: function get() {
    return _PGUniverseInfoDTO["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeDTO", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeDTO["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeDTOITM", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeDTOITM["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeDTOPGR", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeDTOPGR["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeDTOPUB", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeDTOPUB["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeDTOVLD", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeDTOVLD["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeItemDTOITM", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeItemDTOITM["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeItemDTOPUB", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeItemDTOPUB["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeItemNewDTOITM", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeItemNewDTOITM["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeItemUpdateDTOITM", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeItemUpdateDTOITM["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeItemsApi", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeItemsApi["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeItemsPublishedApi", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeItemsPublishedApi["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeItemsResponseDTOITM", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeItemsResponseDTOITM["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeNewDTO", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeNewDTO["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeRefreshStorageApi", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeRefreshStorageApi["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeReportApi", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeReportApi["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeReportResponseDTOPGR", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeReportResponseDTOPGR["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeStatusEnum", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeStatusEnum["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeTypeEnum", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeTypeEnum["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteeUpdateDTO", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteeUpdateDTO["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteesAccountingApi", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteesAccountingApi["default"];
  }
});
Object.defineProperty(exports, "PriceGuaranteesApi", {
  enumerable: true,
  get: function get() {
    return _PriceGuaranteesApi["default"];
  }
});
Object.defineProperty(exports, "SalesAccountTypeEnum", {
  enumerable: true,
  get: function get() {
    return _SalesAccountTypeEnum["default"];
  }
});
Object.defineProperty(exports, "SpecificationChangeTypeEnum", {
  enumerable: true,
  get: function get() {
    return _SpecificationChangeTypeEnum["default"];
  }
});
var _ApiClient = _interopRequireDefault(require("./ApiClient"));
var _AccountingBasisDTO = _interopRequireDefault(require("./model/AccountingBasisDTO"));
var _COrderDTOVLD = _interopRequireDefault(require("./model/COrderDTOVLD"));
var _COrderHeaderAccountPartDTOPRG = _interopRequireDefault(require("./model/COrderHeaderAccountPartDTOPRG"));
var _COrderHeaderAccountPartDTOVLD = _interopRequireDefault(require("./model/COrderHeaderAccountPartDTOVLD"));
var _COrderHeaderAccountWithPGItemsDTOPRG = _interopRequireDefault(require("./model/COrderHeaderAccountWithPGItemsDTOPRG"));
var _COrderHeaderAccountWithPGItemsDTOVLD = _interopRequireDefault(require("./model/COrderHeaderAccountWithPGItemsDTOVLD"));
var _COrderModeEnum = _interopRequireDefault(require("./model/COrderModeEnum"));
var _CarSpecificationDTOVLD = _interopRequireDefault(require("./model/CarSpecificationDTOVLD"));
var _ClaimOriginEnum = _interopRequireDefault(require("./model/ClaimOriginEnum"));
var _ClaimTypeHeaderEnum = _interopRequireDefault(require("./model/ClaimTypeHeaderEnum"));
var _ClaimTypeHeaderFilterEnum = _interopRequireDefault(require("./model/ClaimTypeHeaderFilterEnum"));
var _ClaimTypeItemEnum = _interopRequireDefault(require("./model/ClaimTypeItemEnum"));
var _CohPartStatusEnum = _interopRequireDefault(require("./model/CohPartStatusEnum"));
var _CommissionOrderTypeEnum = _interopRequireDefault(require("./model/CommissionOrderTypeEnum"));
var _CommissionPGClaimResultDTOPGR = _interopRequireDefault(require("./model/CommissionPGClaimResultDTOPGR"));
var _CommissionSnapshotDTOPGR = _interopRequireDefault(require("./model/CommissionSnapshotDTOPGR"));
var _CommissionSnapshotDTOVLD = _interopRequireDefault(require("./model/CommissionSnapshotDTOVLD"));
var _CommissionSpecificationDTOVLD = _interopRequireDefault(require("./model/CommissionSpecificationDTOVLD"));
var _CommissionStatusIntervalEnum = _interopRequireDefault(require("./model/CommissionStatusIntervalEnum"));
var _CommissionValidationResultDTOVLD = _interopRequireDefault(require("./model/CommissionValidationResultDTOVLD"));
var _ConditionCiColumnDefinitionDTOVLD = _interopRequireDefault(require("./model/ConditionCiColumnDefinitionDTOVLD"));
var _ConditionHeaderRowDTOVLD = _interopRequireDefault(require("./model/ConditionHeaderRowDTOVLD"));
var _ConditionItemColumnDTOVLD = _interopRequireDefault(require("./model/ConditionItemColumnDTOVLD"));
var _ConditionResultHeaderEnum = _interopRequireDefault(require("./model/ConditionResultHeaderEnum"));
var _ConditionResultItemEnum = _interopRequireDefault(require("./model/ConditionResultItemEnum"));
var _CurrencyEnum = _interopRequireDefault(require("./model/CurrencyEnum"));
var _ErrorDTO = _interopRequireDefault(require("./model/ErrorDTO"));
var _ErrorItemDTO = _interopRequireDefault(require("./model/ErrorItemDTO"));
var _ErrorTypeEnum = _interopRequireDefault(require("./model/ErrorTypeEnum"));
var _LegalSuccessorTO = _interopRequireDefault(require("./model/LegalSuccessorTO"));
var _ModelGroupDTO = _interopRequireDefault(require("./model/ModelGroupDTO"));
var _PGAccountingDTO = _interopRequireDefault(require("./model/PGAccountingDTO"));
var _PGAccountingNewDTO = _interopRequireDefault(require("./model/PGAccountingNewDTO"));
var _PGIBulkUpdateDTOITM = _interopRequireDefault(require("./model/PGIBulkUpdateDTOITM"));
var _PGISpecificationDTO = _interopRequireDefault(require("./model/PGISpecificationDTO"));
var _PGISpecificationSnapshotEnum = _interopRequireDefault(require("./model/PGISpecificationSnapshotEnum"));
var _PGISpecificationTypeEnum = _interopRequireDefault(require("./model/PGISpecificationTypeEnum"));
var _PGItemDTOPGR = _interopRequireDefault(require("./model/PGItemDTOPGR"));
var _PGItemValidationResultDTOPRG = _interopRequireDefault(require("./model/PGItemValidationResultDTOPRG"));
var _PGItemValidationResultDTOVLD = _interopRequireDefault(require("./model/PGItemValidationResultDTOVLD"));
var _PGUniverseInfoDTO = _interopRequireDefault(require("./model/PGUniverseInfoDTO"));
var _PriceGuaranteeDTO = _interopRequireDefault(require("./model/PriceGuaranteeDTO"));
var _PriceGuaranteeDTOITM = _interopRequireDefault(require("./model/PriceGuaranteeDTOITM"));
var _PriceGuaranteeDTOPGR = _interopRequireDefault(require("./model/PriceGuaranteeDTOPGR"));
var _PriceGuaranteeDTOPUB = _interopRequireDefault(require("./model/PriceGuaranteeDTOPUB"));
var _PriceGuaranteeDTOVLD = _interopRequireDefault(require("./model/PriceGuaranteeDTOVLD"));
var _PriceGuaranteeItemDTOITM = _interopRequireDefault(require("./model/PriceGuaranteeItemDTOITM"));
var _PriceGuaranteeItemDTOPUB = _interopRequireDefault(require("./model/PriceGuaranteeItemDTOPUB"));
var _PriceGuaranteeItemNewDTOITM = _interopRequireDefault(require("./model/PriceGuaranteeItemNewDTOITM"));
var _PriceGuaranteeItemUpdateDTOITM = _interopRequireDefault(require("./model/PriceGuaranteeItemUpdateDTOITM"));
var _PriceGuaranteeItemsResponseDTOITM = _interopRequireDefault(require("./model/PriceGuaranteeItemsResponseDTOITM"));
var _PriceGuaranteeNewDTO = _interopRequireDefault(require("./model/PriceGuaranteeNewDTO"));
var _PriceGuaranteeReportResponseDTOPGR = _interopRequireDefault(require("./model/PriceGuaranteeReportResponseDTOPGR"));
var _PriceGuaranteeStatusEnum = _interopRequireDefault(require("./model/PriceGuaranteeStatusEnum"));
var _PriceGuaranteeTypeEnum = _interopRequireDefault(require("./model/PriceGuaranteeTypeEnum"));
var _PriceGuaranteeUpdateDTO = _interopRequireDefault(require("./model/PriceGuaranteeUpdateDTO"));
var _SalesAccountTypeEnum = _interopRequireDefault(require("./model/SalesAccountTypeEnum"));
var _SpecificationChangeTypeEnum = _interopRequireDefault(require("./model/SpecificationChangeTypeEnum"));
var _CommissionValidatorApi = _interopRequireDefault(require("./api/CommissionValidatorApi"));
var _PriceGuaranteeItemsApi = _interopRequireDefault(require("./api/PriceGuaranteeItemsApi"));
var _PriceGuaranteeItemsPublishedApi = _interopRequireDefault(require("./api/PriceGuaranteeItemsPublishedApi"));
var _PriceGuaranteeRefreshStorageApi = _interopRequireDefault(require("./api/PriceGuaranteeRefreshStorageApi"));
var _PriceGuaranteeReportApi = _interopRequireDefault(require("./api/PriceGuaranteeReportApi"));
var _PriceGuaranteesApi = _interopRequireDefault(require("./api/PriceGuaranteesApi"));
var _PriceGuaranteesAccountingApi = _interopRequireDefault(require("./api/PriceGuaranteesAccountingApi"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }