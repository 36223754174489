
export const moduleCode = 'PARTNER';

/**
 * REST API base path
 *
 * @type {string}
 */
export const apiBasePath = '/partner/web-api';

/**
 * Path prefix for module paths (routes)
 *
 * @type {string}
 */
export const modulePathPrefix = '/partner';

/**
 * Module name for reducer
 * @type {string}
 */
export const moduleReducerName = 'partner';

/**
 * Redux selector for user info.
 *
 * @param state
 * @return UserInfoDTO
 */
export const userInfoSelector = state => null;

/**
 * Redux selector for initialization status.
 *
 * @param state
 * @return {string} of InitializationStatus
 */
export const initializationStatusSelector = state => state.partner.initialization.status;