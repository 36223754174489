import { connect } from 'react-redux';

import {
  handleGetDealerConfigStockCarList,
  handleGetProductionCorridorList,
  handleUpdateDealerConfigStockCarBulk,
  handleUpdateProductionCorridorBulk
} from '../../redux/modules/actions';

import DealerConfigStockCar from './DealerConfigStockCar';
import { handleGetActiveDealersList } from '../../../partner/redux/modules/dealers/dealers';

const mapStateToProps = state => ({
  isFetchingProductionCorridor: state.pvt.productionCorridor.isFetching,
  isFetchingDealerConfig: state.pvt.dealerConfig.isFetching,
  productionCorridorData: state.pvt.productionCorridor.productionCorridorData,
  dealerConfigData: state.pvt.dealerConfig.dealerConfigData,
  activeDealersData: state.pvt.dealers.activeDealersData
});

const mapDispatchToProps = dispatch => ({
  onGetProductionCorridorList: () => dispatch(handleGetProductionCorridorList()),
  onUpdateProductionCorridorBulk: (productionCorridorBulkUpdate) => dispatch(handleUpdateProductionCorridorBulk(productionCorridorBulkUpdate)),
  onGetDealerConfigStockCarList: () => dispatch(handleGetDealerConfigStockCarList()),
  onUpdateConfigSpecification: (dealerConfigBulkUpdate) => dispatch(handleUpdateDealerConfigStockCarBulk(dealerConfigBulkUpdate)),

  onGetActiveDealersList: () => dispatch(handleGetActiveDealersList())
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerConfigStockCar);