import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';

import { LsiContext } from '../../../../common/contexts';
import { usePrivilege } from '../../../hooks';
import { handleGetDefaultContextMenuItems } from '../index';
import leafRowOddEvenClassRules from '../rowClassRules/leafRowOddEvenClassRules';
import AGgridTooltip from '../components/AGgridTooltip/AGGridTooltip';

import { GROUP_DISPLAY_TYPE } from './consts';
// Importing all modules from ag-grid-enterprise to enable full functionality of the enterprise features
import 'ag-grid-enterprise';
import './OvexAGTable.scss';
import { excelStyles } from './OvexAGTable.excelStyles';

const OvexAGTable = React.memo(React.forwardRef((props, ref) => {
  const lsi = React.useContext(LsiContext);

  const { handleIsPrivileged } = usePrivilege();

  const classNames = ['ovex-ag-grid ag-theme-balham', props.className];
  if (props.headerNameRows > 1) {
    classNames.push('ovex-ag-grid--th-wrap');
  }
  if (props.highlightLeafOddEvenRows) {
    classNames.push('ovex-ag-grid--highlightLeafOddEvenRows');
  }

  const onGridReady = props.onGridReady;
  const handleGridReady = React.useCallback((params) => {
    onGridReady && onGridReady(params);
    ref && (ref.current = params);
  }, [onGridReady, ref]);

  const context = {
    ...props.agContext,
    agLsi: lsi,
    onAgIsPrivileged: handleIsPrivileged
  };

  const handleGetContextMenuItemsTrimSeparators = (params) => {
    if (props.getContextMenuItems == null) {
      return handleGetDefaultContextMenuItems(params, context.excelExportParams);
    }
    // remove *separator* item from first or last position
    const contextMenuItems = props.getContextMenuItems(params).filter(item => item != null);

    while (contextMenuItems[0] === 'separator') {
      contextMenuItems.shift();
    }
    while (contextMenuItems[contextMenuItems.length-1] === 'separator') {
      contextMenuItems.pop();
    }

    return contextMenuItems;
  };

  const getRowClassRules = () => {
    return {
      ...props.rowClassRules,
      ...(props.highlightLeafOddEvenRows && leafRowOddEvenClassRules)
    };
  };

  const agGridExcelStyles = [
    ...(props.excelStyles || []),
    ...excelStyles
  ];

  const agGridComponents = {
    ...props.components,
    AGgridTooltip: AGgridTooltip
  };

  const defaultColDef = {
    ...props.defaultColDef,
    tooltipComponent: 'AGgridTooltip'
  };
  const popupParent = useMemo(() => {
    return document.querySelector('body');
  }, []);

  const gridOptionWithDefault = {
    ...props.gridOptions,
    tooltipShowDelay: props.gridOptions?.tooltipShowDelay || 500
  };

  return (
    <div
      className={classNames.join(' ')}
      style={{
        height: ['autoHeight', 'print'].includes(props.domLayout) ? '' : props.height,
        width: props.width
      }}
    >
      <AgGridReact
        alwaysShowVerticalScroll={props.alwaysShowVerticalScroll}
        animateRows={props.animateRows}
        autoGroupColumnDef={props.autoGroupColumnDef}
        columnDefs={props.columnDefs}
        columnTypes={props.columnTypes}
        components={agGridComponents}
        context={context}
        defaultColDef={defaultColDef}
        defaultGroupOrderComparator={props.defaultGroupOrderComparator}
        detailCellRenderer={props.detailCellRenderer}
        detailRowHeight={props.detailRowHeight}
        domLayout={props.domLayout}
        embedFullWidthRows={props.embedFullWidthRows}
        enableFillHandle={props.enableFillHandle}
        enableRangeSelection={props.enableRangeSelection}
        excelStyles={agGridExcelStyles}
        getContextMenuItems={handleGetContextMenuItemsTrimSeparators}
        getDataPath={props.getTreeDataPath}
        getRowClass={props.getRowClass}
        getRowHeight={props.getRowHeight}
        getRowId={props.getRowId}
        gridOptions={gridOptionWithDefault}
        groupDefaultExpanded={props.groupDefaultExpanded}
        groupDisplayType={props.groupDisplayType}
        groupHeaderHeight={props.groupHeaderHeight}
        groupHideOpenParents={props.groupHideOpenParents}
        groupIncludeFooter={props.groupIncludeFooter}
        groupIncludeTotalFooter={props.groupIncludeTotalFooter}
        groupRemoveLowestSingleChildren={props.groupRemoveLowestSingleChildren}
        groupRowAggNodes={props.groupRowAggNodes}
        groupRowRendererParams={props.groupRowRendererParams}
        groupSelectsChildren={props.groupSelectsChildren}
        headerHeight={16 * props.headerNameRows + 16} // 16 = text height per row | 16 = 8 + 8 (top, bottom "padding")
        isRowSelectable={props.isRowSelectable}
        localeText={lsi.getLSIItem('AG_GRID')}
        masterDetail={props.masterDetail}
        onColumnGroupOpened={props.onColumnGroupOpened}
        onFirstDataRendered={props.onFirstDataRendered}
        onGridReady={handleGridReady}
        onGridSizeChanged={props.onGridSizeChanged}
        onRowDataUpdated={props.onRowDataUpdated}
        onRowSelected={props.onRowSelected}
        onSelectionChanged={props.onSelectionChanged}
        pinnedBottomRowData={props.pinnedBottomRowData}
        pinnedTopRowData={props.pinnedTopRowData}
        popupParent={popupParent}
        postSortRows={props.postSortRows}
        processCellFromClipboard={props.processCellFromClipboard}
        rowClassRules={getRowClassRules()}
        rowData={props.rowData}
        RowDataUpdatedEvent={props.onRowDataChanged}
        rowMultiSelectWithClick={props.rowMultiSelectWithClick}
        rowSelection={props.rowSelection}
        showOpenedGroup={props.showOpenedGroup}
        stopEditingWhenCellsLoseFocus
        suppressAggFuncInHeader
        suppressClickEdit={props.suppressClickEdit}
        suppressMenuHide
        suppressMovableColumns={props.suppressMovableColumns}
        suppressRowClickSelection={props.suppressRowClickSelection}
        suppressRowTransform={props.suppressRowTransform}
        suppressScrollOnNewData
        treeData={!!props.getTreeDataPath}
      />
    </div>
  );
}));

OvexAGTable.propTypes = {
  agContext: PropTypes.object,
  alwaysShowVerticalScroll: PropTypes.bool,
  animateRows: PropTypes.bool,
  autoGroupColumnDef: PropTypes.object,
  className: PropTypes.string,
  columnDefs: PropTypes.arrayOf(PropTypes.object),
  columnTypes: PropTypes.object,
  components: PropTypes.object,
  defaultColDef: PropTypes.object,
  defaultGroupOrderComparator: PropTypes.func,
  detailCellRenderer: PropTypes.string,
  detailRowHeight: PropTypes.number,
  domLayout: PropTypes.oneOf(['normal', 'autoHeight', 'print']),
  embedFullWidthRows: PropTypes.bool,
  enableFillHandle: PropTypes.bool,
  enableRangeSelection: PropTypes.bool,
  excelStyles: PropTypes.arrayOf(PropTypes.object),
  getContextMenuItems: PropTypes.func,
  getRowClass: PropTypes.func,
  getRowHeight: PropTypes.func,
  getTreeDataPath: PropTypes.func,
  gridOptions: PropTypes.object,
  groupDefaultExpanded: PropTypes.number,
  groupDisplayType: PropTypes.oneOf([...Object.values(GROUP_DISPLAY_TYPE), null]),
  groupHeaderHeight: PropTypes.number,
  groupHideOpenParents: PropTypes.bool,
  groupIncludeFooter: PropTypes.bool,
  groupIncludeTotalFooter: PropTypes.bool,
  groupRemoveLowestSingleChildren: PropTypes.bool,
  groupRowAggNodes: PropTypes.func,
  groupRowRendererParams: PropTypes.object,
  groupSelectsChildren: PropTypes.bool,
  headerNameRows: PropTypes.number,
  height: PropTypes.string,
  highlightLeafOddEvenRows: PropTypes.bool,
  isRowSelectable: PropTypes.func,
  masterDetail: PropTypes.bool,
  onColumnGroupOpened: PropTypes.func,
  onFirstDataRendered: PropTypes.func,
  onGridReady: PropTypes.func,
  onGridSizeChanged: PropTypes.func,
  onRowDataChanged: PropTypes.func,
  onRowSelected: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  pinnedBottomRowData: PropTypes.arrayOf(PropTypes.object),
  pinnedTopRowData: PropTypes.arrayOf(PropTypes.object),
  postSortRows: PropTypes.func,
  processCellFromClipboard: PropTypes.func,
  rowClassRules: PropTypes.object,
  rowData: PropTypes.arrayOf(PropTypes.object),
  rowMultiSelectWithClick: PropTypes.bool,
  rowSelection: PropTypes.oneOf(['single', 'multiple']),
  showOpenedGroup: PropTypes.bool,
  suppressClickEdit: PropTypes.bool,
  suppressMovableColumns: PropTypes.bool,
  suppressRowClickSelection: PropTypes.bool,
  suppressRowTransform: PropTypes.bool,
  width: PropTypes.string
};

OvexAGTable.defaultProps = {
  agContext: null,
  alwaysShowVerticalScroll: false,
  animateRows: false,
  autoGroupColumnDef: null,
  className: null,
  columnDefs: null,
  columnTypes: null,
  components: null,
  defaultColDef: null,
  defaultGroupOrderComparator: undefined,
  detailCellRenderer: null,
  detailRowHeight: 0,
  domLayout: 'normal',
  embedFullWidthRows: false,
  enableFillHandle: false,
  enableRangeSelection: false,
  excelStyles: null,
  getContextMenuItems: undefined,
  getRowClass: undefined,
  getRowHeight: undefined,
  getTreeDataPath: undefined,
  gridOptions: null,
  groupDefaultExpanded: 0,
  groupDisplayType: null,
  groupHeaderHeight: null,
  groupHideOpenParents: false,
  groupIncludeFooter: false,
  groupIncludeTotalFooter: false,
  groupRemoveLowestSingleChildren: false,
  groupRowAggNodes: undefined,
  groupRowRendererParams: undefined,
  groupSelectsChildren: false,
  headerNameRows: 1,
  height: '600px',
  highlightLeafOddEvenRows: false,
  isRowSelectable: null,
  masterDetail: false,
  onColumnGroupOpened: undefined,
  onFirstDataRendered: undefined,
  onGridReady: undefined,
  onGridSizeChanged: undefined,
  onRowDataChanged: null,
  onRowSelected: null,
  onSelectionChanged: null,
  pinnedBottomRowData: null,
  pinnedTopRowData: null,
  postSortRows: undefined,
  processCellFromClipboard: undefined,
  rowClassRules: null,
  rowData: null,
  rowMultiSelectWithClick: false,
  rowSelection: null,
  showOpenedGroup: false,
  suppressClickEdit: false,
  suppressMovableColumns: false,
  suppressRowClickSelection: false,
  suppressRowTransform: false,
  width: '100%'
};

export default OvexAGTable;
