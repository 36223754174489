export const COLUMN_IDS = {
  MODEL_GROUP: 'modelGroup'
};

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab']
  }
};


export const getColumnDefinitionMap = {
  [COLUMN_IDS.MODEL_GROUP]: (lsi) => ({
    colId: COLUMN_IDS.MODEL_GROUP,
    valueGetter: 'data.modelGroup',
    headerName: lsi.getLSIItem('PVT.TABLE_HEADER.MODEL_GROUP'),
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    flex: 1
  })
};

