

export const getDeletedDealers = (values, dealersSelected, items) => {
  return values
    .filter((dealerNumber1) => !dealersSelected.some((dealerNumber2) => dealerNumber1 === dealerNumber2))
    .map(value => {
      const dealer = items.find(dealer => dealer.dealerNumber === value);
      return { dealerId: dealer.dealerId, dealerNumber: dealer.dealerNumber };
    });
};


export const getAddedDealers = (values, dealersSelected) => {
  return dealersSelected
    .filter((dealerNumber1) => !values.some((dealerNumber2) => dealerNumber1 === dealerNumber2))
    .map((dealerNumber) => Number(dealerNumber));
};