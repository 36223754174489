import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const dealerConfigApi = ApiServiceInstances.pvt.dealerConfigApi;

export const GET_DEALER_CONFIG_STOCK_CAR_REQUEST = 'GET_DEALER_CONFIG_STOCK_CAR_REQUEST';
export const GET_DEALER_CONFIG_STOCK_CAR_SUCCESS = 'GET_DEALER_CONFIG_STOCK_CAR_SUCCESS';
export const GET_DEALER_CONFIG_STOCK_CAR_FAILURE = 'GET_DEALER_CONFIG_STOCK_CAR_FAILURE';

export const UPDATE_DEALER_CONFIG_STOCK_CAR_REQUEST = 'UPDATE_DEALER_CONFIG_STOCK_CAR_REQUEST';
export const UPDATE_DEALER_CONFIG_STOCK_CAR_SUCCESS = 'UPDATE_DEALER_CONFIG_STOCK_CAR_SUCCESS';
export const UPDATE_DEALER_CONFIG_STOCK_CAR_FAILURE = 'UPDATE_DEALER_CONFIG_STOCK_CAR_FAILURE';

export const GET_DEALER_CONFIG_ROC_REQUEST = 'GET_DEALER_CONFIG_ROC_REQUEST';
export const GET_DEALER_CONFIG_ROC_SUCCESS = 'GET_DEALER_CONFIG_ROC_SUCCESS';
export const GET_DEALER_CONFIG_ROC_FAILURE = 'GET_DEALER_CONFIG_ROC_FAILURE';

export const UPDATE_DEALER_CONFIG_ROC_REQUEST = 'UPDATE_DEALER_CONFIG_ROC_REQUEST';
export const UPDATE_DEALER_CONFIG_ROC_SUCCESS = 'UPDATE_DEALER_CONFIG_ROC_SUCCESS';
export const UPDATE_DEALER_CONFIG_ROC_FAILURE = 'UPDATE_DEALER_CONFIG_ROC_FAILURE';

const initialState = {
  dealerConfigData: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEALER_CONFIG_STOCK_CAR_REQUEST:
    case GET_DEALER_CONFIG_ROC_REQUEST:
      return { ...state, isFetching: true };
    case GET_DEALER_CONFIG_STOCK_CAR_SUCCESS:
    case GET_DEALER_CONFIG_ROC_SUCCESS:
      return { ...state, isFetching: false, dealerConfigData: action.payload };
    case GET_DEALER_CONFIG_STOCK_CAR_FAILURE:
    case GET_DEALER_CONFIG_ROC_FAILURE:
      return { ...state, error: action.payload.error, isFetching: false };
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetDealerConfigStockCarList = () => {
  return {
    [CALL_API]: {
      apiFunction: dealerConfigApi.getDealerStockCarConfigurationsWithHttpInfo.bind(dealerConfigApi),
      types: [GET_DEALER_CONFIG_STOCK_CAR_REQUEST, GET_DEALER_CONFIG_STOCK_CAR_SUCCESS, GET_DEALER_CONFIG_STOCK_CAR_FAILURE]
    }
  };
};

export const handleUpdateDealerConfigStockCarBulk = (dealerConfigBulkUpdate) => {
  return {
    [CALL_API]: {
      apiFunction: dealerConfigApi.updateDealerStockCarConfigurationsBulkWithHttpInfo.bind(dealerConfigApi, dealerConfigBulkUpdate),
      types: [UPDATE_DEALER_CONFIG_STOCK_CAR_REQUEST, UPDATE_DEALER_CONFIG_STOCK_CAR_SUCCESS, UPDATE_DEALER_CONFIG_STOCK_CAR_FAILURE]
    }
  };
};

export const handleGetDealerConfigROCList = () => {
  return {
    [CALL_API]: {
      apiFunction: dealerConfigApi.getDealerAnnualTargetConfigurationsWithHttpInfo.bind(dealerConfigApi),
      types: [GET_DEALER_CONFIG_ROC_REQUEST, GET_DEALER_CONFIG_ROC_SUCCESS, GET_DEALER_CONFIG_ROC_FAILURE]
    }
  };
};

export const handleUpdateDealerConfigROCBulk = (dealerConfigBulkUpdate) => {
  return {
    [CALL_API]: {
      apiFunction: dealerConfigApi.updateDealerAnnualTargetConfigurationsBulkWithHttpInfo.bind(dealerConfigApi, dealerConfigBulkUpdate),
      types: [UPDATE_DEALER_CONFIG_ROC_REQUEST, UPDATE_DEALER_CONFIG_ROC_SUCCESS, UPDATE_DEALER_CONFIG_ROC_FAILURE]
    }
  };
};

