import React from 'react';
import PropTypes from 'prop-types';

import { ListSelector } from '../../../../../common/components/forms';
import { Button, ButtonBar, Modal } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';

import { COLUMN_IDS, columnTypes, getColumnDefinitionMap } from './DealerListModalForm.columnDefs';
import { getAddedDealers, getDeletedDealers } from './DealerListModalFormHelper';

const propTypes = {
  items: PropTypes.array,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  shown: PropTypes.bool,
  values: PropTypes.array
};

const defaultProps = {

  items: [],
  onClose: null,
  onSave: null,
  shown: false,
  values: []
};

const DealerListModalFormComponent = (props) => {
  const lsi = React.useContext(LsiContext);
  const { items, values } = props;
  const dealerNameColumnDef = getColumnDefinitionMap[COLUMN_IDS.DEALER_NAME](lsi);
  const dealerNumberColumnDef = getColumnDefinitionMap[COLUMN_IDS.DEALER_NUMBER](lsi);
  const [dealersSelected, setDealersListSelected] = React.useState(values);


  const handleSave = () => {
    const { onSave } = props;

    const deletedDealers = getDeletedDealers(values, dealersSelected, items);
    const addedDealers = getAddedDealers(values, dealersSelected);
    const mappedSelectedDealers = dealersSelected.map(value => items.find(dealer => dealer.dealerNumber === value));

    onSave && onSave({
      values: mappedSelectedDealers,
      addedDealers: addedDealers,
      deletedDealers: deletedDealers
    });
  };

  return (
    <Modal
      header={lsi.getLSIItem('PVT.PAGE_TITLE.EDIT_DEALERS')}
      onClose={props.onClose}
      shown={props.shown}
      size={'l'}
    >
      <ListSelector
        columnDefAvailableOptions={[
          dealerNameColumnDef,
          dealerNumberColumnDef
        ]}
        columnDefSelectedOptions={[
          dealerNameColumnDef,
          dealerNumberColumnDef
        ]}
        columnTypes={columnTypes}
        enableRangeSelection
        items={items}
        onChange={(params) => {
          setDealersListSelected(params.map(item => item.value));
        }}
        value={dealersSelected}

      />
      <ButtonBar
        align={ButtonBar.align.center}
        formControlsBar
      >
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.CANCEL')}
          onClick={props.onClose}
          type={Button.type.SECONDARY}
        />
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.SAVE')}
          onClick={handleSave}
          type={Button.type.PRIMARY}
        />
      </ButtonBar>
    </Modal>

  );
};

DealerListModalFormComponent.defaultProps = defaultProps;
DealerListModalFormComponent.propTypes = propTypes;

export default DealerListModalFormComponent;