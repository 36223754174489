import { PrivilegeApi } from '@ovex/privilege-web-api';
import { UserInfoApi } from '@ovex/about-web-api';
import { DealersWebApi } from '@ovex/partner-web-api';
import {
  BalanceApi,
  DealerWithSpecificConfigurationsApi,
  ProductionCorridorApi,
  RestrictionsApi,
  SwapCommissionsPvtApi
} from '@ovex/pvt-web-api';

import ApiClientInstances from './ApiClientInstances';

/**
 * Api service instances
 */
const ApiServiceInstances = {
  about: {
    userInfoApi: new UserInfoApi(ApiClientInstances.about)
  },
  privilege: {
    privilegeApi: new PrivilegeApi(ApiClientInstances.privilege)
  },

  pvt: {
    restrictionsApi: new RestrictionsApi(ApiClientInstances.pvt),
    productionCorridorApi: new ProductionCorridorApi(ApiClientInstances.pvt),
    balanceApi: new BalanceApi(ApiClientInstances.pvt),
    swapCommissionPvtApi: new SwapCommissionsPvtApi(ApiClientInstances.pvt),
    dealerConfigApi: new DealerWithSpecificConfigurationsApi(ApiClientInstances.pvt),
    dealersApi: new DealersWebApi(ApiClientInstances.pvt)
  }
};

export default ApiServiceInstances;
