import React, { Fragment } from 'react';
import { Bricks } from 'uu5g04';
import PropTypes from 'prop-types';
import { ProductionCorridorDTO, ProductionCorridorModelGroupChangeDTO } from '@ovex/pvt-web-api';

import { OvexAGTable } from '../../../../../common/components/ag-grid';
import DealerConfigProductionCorridorButtonBar
  from '../DealerConfigProductionCorridorButtonBar/DealerConfigProductionCorridorButtonBar';
import './DealerConfigProductionConrridorTable.scss';
import { LsiContext } from '../../../../../common/contexts';

import { createColumnDefs, updateCell } from './DealerConfigProductionCorridorTableData';
import { Loading } from '../../../../../common/components';

const propTypes = {
  attributeName: PropTypes.string.isRequired,
  disabledAllTables: PropTypes.bool,
  editableProductionCorridor: PropTypes.bool,
  editableSpecification: PropTypes.bool,
  isFetching: PropTypes.bool,
  onReloadProductionCorridor: PropTypes.func,
  onSetEditableProductionCorridor: PropTypes.func,
  onUpdateProductionCorridor: PropTypes.func,
  pageDescription: PropTypes.shape({
    generalSettingDescription: PropTypes.string,
    legend: PropTypes.string,
    legendDescriptionOff: PropTypes.string,
    legendDescriptionOn: PropTypes.string,
    specificSettingDescription: PropTypes.string,
    tableSpecification: PropTypes.string
  }),
  productionCorridorData: PropTypes.arrayOf(PropTypes.instanceOf(ProductionCorridorDTO))
};

const defaultProps = {
  disabledAllTables: false,
  editableProductionCorridor: false,
  editableSpecification: false,
  isFetching: true,
  onReloadProductionCorridor: undefined,
  onSetEditableProductionCorridor: undefined,
  onUpdateProductionCorridor: undefined,
  pageDescription: PropTypes.shape({
    generalSettingDescription: null,
    legend: null,
    legendDescriptionOff: null,
    legendDescriptionOn: null,
    specificSettingDescription: null,
    tableSpecification: null
  }),
  productionCorridorData: []
};

const DealerConfigProductionCorridorTable = (props) => {
  const {
    editableProductionCorridor,
    editableSpecification,
    isFetching,
    pageDescription,
    attributeName,
    productionCorridorData,
    disabledAllTables
  } = props;
  const { onSetEditableProductionCorridor, onReloadProductionCorridor, onUpdateProductionCorridor } = props;

  const lsi = React.useContext(LsiContext);

  const [changedModelGroups, setChangedModelGroups] = React.useState([]);
  const [rowData, setRowData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const gridRef = React.useRef(null);

  const modelGroups = React.useMemo(() => {
    return [...new Set(productionCorridorData.map(item => item.modelGroup))];
  }, [productionCorridorData]);


  const showTable = React.useMemo(() => {
    return !!(rowData && rowData.length > 0);
  }, [rowData]);

  const showEmptyData = React.useMemo(() => {
    return !!((!rowData || rowData.length === 0) && !isFetching);
  }, [rowData, isFetching]);

  React.useEffect(() => {
    setRowData(prepareRowData());
  }, [modelGroups, productionCorridorData]);

  React.useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api.refreshCells({ force: true });
    }
  }, [editableProductionCorridor]);

  const onChange = React.useCallback((params, value, item) => {
    updateCell(gridRef, value, attributeName, item.modelGroup);
    setChangedModelGroups((prevState) => {
      if (prevState.some(changedModelGroup => changedModelGroup.modelGroup === item.modelGroup)) {
        return prevState.filter((existingItem) => existingItem.modelGroup !== item.modelGroup);
      } else {
        return [...prevState, { ...item, [attributeName]: value }];
      }
    });
  }, [gridRef, attributeName]);

  const prepareRowData = React.useCallback(() => {
    if (modelGroups.length === 0) {
      return [];
    }
    const singleRow = modelGroups.reduce((acc, modelGroup) => {
      const item = productionCorridorData.find(item => item.modelGroup === modelGroup);
      acc[modelGroup] = item ? item[attributeName] : false;
      return acc;
    }, {});
    return [singleRow];
  }, [productionCorridorData, modelGroups]);

  const handleCancelProductionCorridorChanges = () => {
    if (changedModelGroups.length > 0) {
      setRowData(prepareRowData());
      setChangedModelGroups([]);
    }
    onSetEditableProductionCorridor(false);
  };

  const handleSaveProductionCorridorChanges = async () => {
    const modelGroupChanges = [];

    changedModelGroups.forEach((modelGroup) => {
      modelGroupChanges.push(ProductionCorridorModelGroupChangeDTO.constructFromObject(modelGroup));
    });

    if (modelGroupChanges.length > 0) {
      setLoading(true);
      const response = await onUpdateProductionCorridor({
        modelGroupChanges: modelGroupChanges
      });
      response && resetStates();
    } else {
      onSetEditableProductionCorridor(false);
    }
  };

  const resetStates = () => {
    setLoading(false);
    setChangedModelGroups([]);
    setRowData([]);
    onSetEditableProductionCorridor(false);
  };

  const enableEditableProductionCorridor = () => {
    onSetEditableProductionCorridor(true);
  };

  const columnDefs = React.useMemo(() => {
    return createColumnDefs(productionCorridorData, onChange, pageDescription.tableSpecification);
  }, [onChange, pageDescription.tableSpecification, productionCorridorData]);

  return (
    <Fragment>
      {loading && <Loading loading={loading}/>}
      <DealerConfigProductionCorridorButtonBar
        disabledAllTables={disabledAllTables}
        editableProductionCorridor={editableProductionCorridor}
        editableSpecification={editableSpecification}
        changedModelGroups={changedModelGroups}
        onCancel={handleCancelProductionCorridorChanges}
        onReloadProductionCorridor={onReloadProductionCorridor}
        onUpdateProductionCorridor={handleSaveProductionCorridorChanges}
        pageDescription={pageDescription}
        setEditable={enableEditableProductionCorridor}
        setChangedModelGroups={setChangedModelGroups}
      />
      {showTable &&
        <OvexAGTable
          agContext={{
            attributeName: attributeName,
            editable: editableProductionCorridor
          }}
          className={'ovex-dealer-config-production-corridor-table'}
          columnDefs={columnDefs}
          domLayout="autoHeight"
          enableFillHandle
          enableRangeSelection
          getRowId={() => {
            return attributeName;
          }}
          ref={gridRef}
          rowData={rowData}
          rowDataChangeDetectionStrategy="IdentityCheck"
          suppressClickEdit
          suppressRowClickSelection
        />
      }
      {showEmptyData &&
        <Bricks.Text className={'ovex-dealer-config-production-corridor-text'}>{lsi.getLSIItem('PVT.LABEL.PRODUCTION_CORRIDOR_NO_DATA')}</Bricks.Text>
      }
    </Fragment>
  );
};

DealerConfigProductionCorridorTable.defaultProps = defaultProps;
DealerConfigProductionCorridorTable.propTypes = propTypes;

export default DealerConfigProductionCorridorTable;