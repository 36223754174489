import { combineReducers } from 'redux';

import { moduleReducerName } from '../../config/moduleConfig';

import productionCorridor from './productionCorridor/productionCorridor';
import dealerConfig from './dealerConfig/dealerConfig';
import dealers from './../../../partner/redux/modules/dealers/dealers';
import balance from './balance/balance';
import restrictions from './restrictions/restrictions';
import swapCommissionPvt from './swapCommissionPvt/swap-commission-pvt';
//
import userInfo from './about/userInfo/userInfo';
//
import privilege from './privilege/privilege/privilege';
//
import initialization from './initialization/initialization';
import availableCapacity from './availableCapacity/availableCapacity';

const moduleReducers = {
  propName: moduleReducerName,
  combineReducers: combineReducers({
    availableCapacity: availableCapacity,
    productionCorridor: productionCorridor,
    dealers: dealers,
    dealerConfig: dealerConfig,
    balance: balance,
    restrictions: restrictions,
    swapCommissionPvt: swapCommissionPvt,

    userInfo: userInfo,

    privilege: privilege,

    initialization: initialization
  })
};

export default moduleReducers;