import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DealerWithSpecificConfigurationResponseDTO, ProductionCorridorDTO } from '@ovex/pvt-web-api';
import { ActiveDealerBasicInfoDTO } from '@ovex/partner-web-api';
import { Bricks } from 'uu5g04';

import { LsiContext } from '../../../common/contexts';

import DealerConfigProductionCorridorTable
  from './components/DealerConfigProductionCorridorTable/DealerConfigProductionCorridorTable';
import DealerConfigSpecificationTable from './components/DealerConfigSpecificationTable/DealerConfigSpecificationTable';

const propTypes = {
  activeDealersData: PropTypes.arrayOf(PropTypes.instanceOf(ActiveDealerBasicInfoDTO)),
  attributeName: PropTypes.string.isRequired,
  dealerConfigData: PropTypes.instanceOf(DealerWithSpecificConfigurationResponseDTO),
  isFetchingDealerConfig: PropTypes.bool,
  isFetchingProductionCorridor: PropTypes.bool,
  onReloadActiveDealers: PropTypes.func,
  onReloadConfigSpecification: PropTypes.func,
  onReloadProductionCorridor: PropTypes.func,
  onUpdateConfigSpecification: PropTypes.func,
  onUpdateProductionCorridor: PropTypes.func,
  pageDescription: PropTypes.shape({
    generalSettingDescription: PropTypes.string,
    legend: PropTypes.string,
    legendDescriptionOff: PropTypes.string,
    legendDescriptionOn: PropTypes.string,
    specificSettingDescription: PropTypes.string,
    tableSpecification: PropTypes.string
  }),
  productionCorridorData: PropTypes.arrayOf(PropTypes.instanceOf(ProductionCorridorDTO))
};

const defaultProps = {
  activeDealersData: null,
  dealerConfigData: null,
  isFetchingDealerConfig: true,
  isFetchingProductionCorridor: true,
  onReloadActiveDealers: undefined,
  onReloadConfigSpecification: undefined,
  onReloadProductionCorridor: undefined,
  onUpdateConfigSpecification: undefined,
  onUpdateProductionCorridor: undefined,
  pageDescription: PropTypes.shape({
    generalSettingDescription: null,
    legend: null,
    legendDescriptionOff: null,
    legendDescriptionOn: null,
    specificSettingDescription: null,
    tableSpecification: null
  }),
  productionCorridorData: []
};

const DealerConfig = (props) => {
  const {
    activeDealersData,
    isFetchingDealerConfig,
    isFetchingProductionCorridor,
    pageDescription,
    productionCorridorData,
    attributeName,
    dealerConfigData
  } = props;
  const {
    onReloadActiveDealers,
    onReloadProductionCorridor,
    onUpdateProductionCorridor,
    onUpdateConfigSpecification,
    onReloadConfigSpecification
  } = props;

  const lsi = React.useContext(LsiContext);

  const [editableProductionCorridor, setEditableProductionCorridor] = React.useState(false);
  const [editableSpecification, setEditableSpecification] = React.useState(false);

  const alertBusRef = React.useRef(null);

  const storeAlertRef = (ref) => {
    alertBusRef.current = ref;
  };

  const disabledAllTables = React.useMemo(() => {
    return !editableProductionCorridor && !editableSpecification;
  }, [editableProductionCorridor, editableSpecification]);

  const { quotas, modelGroups } = React.useMemo(() => {
    const quotas = productionCorridorData?.filter((item) => item.modelMask === null);
    const modelGroups = quotas?.map((item) => ({
      modelGroup: item.modelGroup,
      value: item.modelGroup
    }));
    return { quotas, modelGroups };
  }, [productionCorridorData]);

  React.useEffect(() => {
    dealerConfigData?.dealersFetchFailed && alertBusRef.current.addAlert({
      colorSchema: 'warning',
      content: lsi.getLSIItem('PVT.ERROR_MESSAGE.GET_DEALER_PAR_FAILURE')
    });
  }, [lsi, dealerConfigData]);

  return (
    <Fragment>
      <DealerConfigProductionCorridorTable
        attributeName={attributeName}
        disabledAllTables={disabledAllTables}
        editableProductionCorridor={editableProductionCorridor}
        editableSpecification={editableSpecification}
        isFetching={isFetchingProductionCorridor}
        onReloadProductionCorridor={onReloadProductionCorridor}
        onSetEditableProductionCorridor={setEditableProductionCorridor}
        onUpdateProductionCorridor={onUpdateProductionCorridor}
        pageDescription={pageDescription}
        productionCorridorData={quotas}
      />
      <DealerConfigSpecificationTable
        activeDealersData={activeDealersData}
        attributeName={attributeName}
        dealerConfigData={dealerConfigData?.dealerWithSpecificConfigurationList}
        editableProductionCorridor={editableProductionCorridor}
        editableSpecification={editableSpecification}
        isFetching={isFetchingDealerConfig}
        modelGroupsInProductionCorridor={modelGroups}
        onReloadActiveDealers={onReloadActiveDealers}
        onReloadConfigSpecification={onReloadConfigSpecification}
        onSetEditableSpecification={setEditableSpecification}
        onUpdateConfigSpecification={onUpdateConfigSpecification}
        pageDescription={pageDescription}
      />
      <Bricks.AlertBus
        hidden
        ref_={storeAlertRef}
      />
    </Fragment>
  );
};

DealerConfig.defaultProps = defaultProps;
DealerConfig.propTypes = propTypes;

export default DealerConfig;
