import { DealersWebApi } from '@ovex/partner-web-api';

import ApiClientInstances from './ApiClientInstances';

/**
 * Api service instances
 */
const ApiServiceInstances = {
  partner: {
    dealersApi: new DealersWebApi(ApiClientInstances.partner)
  }
};

export default ApiServiceInstances;
