import React from 'react';
import Icon from '@mdi/react';
import { Bricks } from 'uu5g04';
import './DealerRenderer.scss';
import { mdiArchiveOutline, mdiDelete } from '@mdi/js';

import { DEALER_STATUS } from '../DealerConfigSpecificationTable/DealerConfigSpecificationTableData';

const DealerRenderer = (lsi, params) => {
  const dealerClassNames = ['ovex-dealer-renderer-text'];
  let tooltip;
  let iconPath;

  if (params.data.status === DEALER_STATUS.ARCHIVED) {
    dealerClassNames.push('dealer-name-not-active');
    tooltip = lsi.getLSIItem('PVT.TOOLTIP.DEALER_ARCHIVED');
    iconPath = mdiArchiveOutline;
  } else if (params.data.status === DEALER_STATUS.NOT_FOUND) {
    dealerClassNames.push('dealer-name-not-active');
    iconPath = mdiDelete;
  }
  if (params.data.dealerId === null) {
    dealerClassNames.push('ovex-dealer-renderer-text-new-dealer');
  }
  return (
    <div className={'ovex-dealer-renderer'}>
      {iconPath &&
        <div className={'icon'}>
          <Icon
            path={iconPath}
            size={0.6}
            title={tooltip}
          />
        </div>
      }
      <Bricks.Text className={dealerClassNames.join(' ')}>{params.value}</Bricks.Text>
    </div>
  );

};

export default DealerRenderer;
