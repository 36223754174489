import React from 'react';
import './CheckBoxRenderer.scss';
import Icon from '@mdi/react';
import { mdiAlert } from '@mdi/js';

import { Checkbox } from '../../../../../common/components/forms';

const CheckBoxRenderer = (params, editable, tooltipSpecificationNotFound = '') => {
  if (params.value === undefined) {
    return (
      <div className={'ovex-check-box-renderer-icon'} title={tooltipSpecificationNotFound}>
        <Icon color={'red'} path={mdiAlert} size={0.9}/>
      </div>
    );
  }
  const cellClass = ['ovex-check-box-renderer'];

  return (
    <div className={cellClass.join(' ')}>
      <Checkbox
        disabled={!editable}
        onChange={(checkBoxparams) => {
          params.onChange(params, checkBoxparams);
        }}
        type={1}
        value={params.value}
      />
    </div>
  );
};

export default CheckBoxRenderer;